html{
  height: 100%;
  font-size: 16px;
}
body{
  height: 100%;
}
#root{
  height: 100%;
}
#app-frame{
  display: flex;
  flex-direction: row;
  height: 100%;
}